import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { useTheme } from "../../ThemeContext";
 
function Home() {
  const { isDarkMode } = useTheme();
 
  return (
    
      <div className={isDarkMode ? "dark-mode" : "light-mode"}>
        <Container fluid className="home-section" id="home">
          <Particle />
          <Container className="home-content">
            <Row>
              <Col md={7} className="home-header">
                <h1
                  style={{
                    paddingBottom: 15,
                  }}
                  className="heading"
                >
                  Hi There!{" "}
                  <span className="wave" role="img" aria-labelledby="wave">
                    👋🏻
                  </span>
                </h1>
 
                <h1 className="heading-name">
                  I'M
                  <strong className="main-name"> GUL MUAZZAM FAIZE</strong>
                </h1>
 
                <div style={{ padding: 50, textAlign: "left" }}>
                  <Type />
                </div>
              </Col>
              <Col md={5} style={{ paddingBottom: 20 }}>
                <img
                  src={homeLogo}
                  alt="home pic"
                  className="img-fluid"
                  style={{ maxHeight: "450px" }}
                />
              </Col>
            </Row>
          </Container>
          <Home2 />
        </Container>
 
        
      </div>
    
  );
}
 
export default Home;













